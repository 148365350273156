import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useMutation } from '@apollo/client';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Tooltip } from '@material-ui/core';
import Box from 'components/Box';
import CustomFooter from 'components/TablePagination';
import AlbTable from 'components/AlbTable';
import { AlbTooltip } from 'components/AlbTooltip';
import EditInPlaceInput from 'components/EditInPlaceInput';
import { UPDATE_MENTION_GROUP_TITLE } from 'gql/mention_group';
import handleGraphQLError from 'util/error';
import { TV_ICON, RADIO_ICON, PODCAST_ICON } from 'util/assets';
import DashboardIntelligenceDrawerContext from 'contexts/DashboardIntelligenceDrawerContext';
import openDashboardDrawer from 'util/drawerUtil';
import getValueAbbreviation from 'util/getValueAbbreviation';
import customActionsRender from './NarrativeTableActions';
import customPromotionRender from './NarrativeTablePromotion';
import {
  MENTION_GROUP_SOURCE_ENUM,
  mapSourceToEventType,
  excludedRatingsSourceTypes
} from '../util';
import { NARRATIVE } from '../ThirdPartyMediaConsts';

const useStyles = makeStyles(() => ({
  switchLabel: {
    fontSize: '13px',
    lineHeight: '20px'
  },
  reportNameText: {
    fontSize: '13px',
    lineHeight: '20px',
    color: '#000000',
    marginLeft: '5px',
    display: '-webkit-box',
    overflow: 'hidden',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
    lineClamp: 2,
    wordBreak: 'break-word',
    minWidth: '80px'
  },
  narrativeTitleText: {
    fontSize: '13px',
    fontWeight: '600',
    lineHeight: '20px',
    color: '#0A1734'
    // whiteSpace: 'nowrap'
  },
  highlightMentionText: {
    fontSize: '13px',
    lineHeight: '20px',
    color: '#000000',
    display: '-webkit-box',
    overflow: 'hidden',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
    lineClamp: 2,
    wordBreak: 'break-word'
  }
}));

const NarrativeTable = props => {
  const {
    isEnabled,
    dataResponse,
    dataRefetch,
    after,
    setAfter,
    sortOrder,
    setSortOrder,
    startDate,
    endDate
  } = props;

  const [tableData, setTableData] = useState([]);
  const [count, setCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const drawerContext = useContext(DashboardIntelligenceDrawerContext);
  const [drawerDetails, setDrawerDetails] = useState(null);

  const classes = useStyles();

  const [updateMentionGroupTitle, { error: errorTitle, data: dataTitle }] = useMutation(
    UPDATE_MENTION_GROUP_TITLE
  );

  const rowsPerPageOptions = [];

  const setDrawerDetailsObject = narrative => {
    let reportName;

    if (narrative?.broadcast_search_id) {
      reportName = narrative.broadcastSearch.name;
    }

    if (narrative?.podcast_search_id) {
      reportName = narrative.podcastSearch.name;
    }

    return {
      id: narrative.id,
      title: narrative.title ?? `Narrative ${narrative.group_seq + 1}`,
      promotionType: {
        value: narrative.promotion_type,
        id: narrative.id,
        dataRefetch
      },
      type: mapSourceToEventType(narrative.source),
      reportName,
      drawerType: NARRATIVE,
      mediaType: narrative.source,
      text: narrative.highlight_mention_text,
      mentions: narrative?.mentions?.count?.toLocaleString() ?? '-',
      ...(!excludedRatingsSourceTypes.includes(narrative.source) && {
        ratings:
          narrative?.totalViewership?.toString()?.length > 7
            ? getValueAbbreviation(narrative?.totalViewership)
            : narrative?.totalViewership?.toLocaleString() ?? '-'
      }),
      dates: [
        moment.utc(narrative.firstAirDate).format('M/DD/YY'),
        moment.utc(narrative.lastAirDate).format('M/DD/YY')
      ],
      firstAirDate: narrative.firstAirDate,
      lastAirDate: narrative.lastAirDate,
      startDate,
      endDate,
      length: narrative.length
    };
  };

  const formatNarrativeTableData = narrativeTableData => {
    const tempTableData = [];
    let drawerNarrativeId;
    let tempDrawerDetails;

    if (drawerDetails?.id) {
      drawerNarrativeId = drawerDetails.id;
    }

    narrativeTableData.forEach(narrative => {
      let name;

      if (narrative.id === drawerNarrativeId) {
        tempDrawerDetails = setDrawerDetailsObject(narrative);
      }

      if (narrative?.broadcast_search_id) {
        ({ name } = narrative.broadcastSearch);
      }

      if (narrative?.podcast_search_id) {
        ({ name } = narrative.podcastSearch);
      }

      tempTableData.push({
        id: narrative.id,
        reportName: {
          name,
          mediaType: narrative.source
        },
        firstAirDate: narrative.firstAirDateSort,
        length: narrative.length,
        narrative: {
          title: narrative.title ?? `Narrative ${narrative.group_seq + 1}`,
          highlightMentionText: narrative.highlight_mention_text,
          id: narrative.id
        },
        summary: narrative.ai_summary,
        mentionsCount: narrative.mentionsCount,
        ...(!excludedRatingsSourceTypes.includes(narrative.source) && {
          ratingsAndImpactCount: narrative.viewershipCount
        }),
        promotionType: {
          value: narrative.promotion_type,
          id: narrative.id,
          dataRefetch
        },
        actions: {
          disabled: narrative.disabled,
          id: narrative.id,
          name,
          dataRefetch
        }
      });
    });

    if (tempDrawerDetails) {
      setDrawerDetails(tempDrawerDetails);
    }

    return tempTableData;
  };

  const queryName = isEnabled ? 'getEnabledMentionGroups' : 'getDisabledMentionGroups';

  useEffect(() => {
    let tempCount = 0;
    let tempTableData = [];

    if (dataResponse?.[queryName]?.rows?.length > 0) {
      ({ count: tempCount, rows: tempTableData } = dataResponse[queryName]);
    }

    setCount(tempCount);
    setTableData(formatNarrativeTableData(tempTableData));
  }, [dataResponse]);

  useEffect(() => {
    if (errorTitle) {
      handleGraphQLError(errorTitle);
    }
  }, [errorTitle]);

  useEffect(() => {
    if (dataTitle) {
      dataRefetch();
    }
  }, [dataTitle]);

  const customReportNameRender = reportName => (
    <Box display="flex" justifyContent="flex-start" alignItems="center">
      <Box display="flex" justifyContent="center" style={{ width: '30px' }}>
        {reportName?.mediaType === MENTION_GROUP_SOURCE_ENUM.TV && (
          <img src={TV_ICON} alt="Television icon" width={17} height={13} />
        )}
        {reportName?.mediaType === MENTION_GROUP_SOURCE_ENUM.RADIO && (
          <img src={RADIO_ICON} alt="Radio icon" width={17} height={13} />
        )}
        {(reportName?.mediaType === MENTION_GROUP_SOURCE_ENUM.PODCAST_TRANSCRIPT ||
          reportName?.mediaType === MENTION_GROUP_SOURCE_ENUM.PODCAST_SUMMARY) && (
          <img src={PODCAST_ICON} alt="Podcast icon" width={10} height={14} />
        )}
      </Box>
      <Tooltip title={reportName.name.length > 20 ? reportName.name : ''}>
        <Typography variantMapping={{ body1: 'span' }} className={classes.reportNameText}>
          {reportName.name}
        </Typography>
      </Tooltip>
    </Box>
  );

  const customDateRender = date => {
    if (date != null) {
      return (
        <div style={{ whiteSpace: 'nowrap' }}>
          {moment.utc(date).format('M/DD/YY')}
          <br />
          {`@ ${moment.utc(date).format('h:mma')}`}
        </div>
      );
    }
    return <div style={{ textAlign: 'center' }}>-</div>;
  };

  const customNarrativeRender = (narrative, { columnIndex, rowData }) => {
    // summary is placed in col after narrative
    const summary = rowData[columnIndex + 1];
    const text = narrative?.highlightMentionText;

    return (
      <Box display="flex" flexDirection="column" justifyContent="flex-start">
        {summary != null ? (
          <Tooltip title={summary}>
            <Typography variantMapping={{ body1: 'span' }} className={classes.highlightMentionText}>
              {summary}
            </Typography>
          </Tooltip>
        ) : (
          <>
            <EditInPlaceInput
              useEdit
              useIcons
              value={narrative.title}
              onChange={event => {
                updateMentionGroupTitle({
                  variables: {
                    id: narrative.id,
                    title: event.value
                  }
                });
              }}
              customTitleStyle={classes.narrativeTitleText}
            />
            <Tooltip title={text}>
              <Typography
                variantMapping={{ body1: 'span' }}
                className={classes.highlightMentionText}
              >
                {text}
              </Typography>
            </Tooltip>
          </>
        )}
      </Box>
    );
  };

  const customValueRender = value => (
    <div style={{ textAlign: 'right' }}>{value ? Math.round(value).toLocaleString() : '-'}</div>
  );

  const customLengthRender = length => (
    <div style={{ textAlign: 'center' }}>
      {`${length.toLocaleString()} Day${length === 1 ? '' : 's'}`}
    </div>
  );

  const customHeadRender = tableMeta => {
    const tooltip = {
      narrative: 'Narrative',
      ratingsAndImpactCount: 'RatingsAndImpactCount'
    }[tableMeta.name];

    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <span style={{ marginRight: '10px' }}>{tableMeta.label}</span>
        <AlbTooltip lookupKey={tooltip} prefix="tpm" />
      </div>
    );
  };

  const customFooter = (
    footerCount,
    footerPage,
    footerRowsPerPage,
    changeRowsPerPage,
    changePage,
    textLabels
  ) =>
    CustomFooter(
      footerCount,
      footerPage,
      footerRowsPerPage,
      changeRowsPerPage,
      changePage,
      textLabels,
      rowsPerPageOptions
    );

  const tableColumns = [
    {
      name: 'id',
      options: { display: false }
    },
    {
      name: 'reportName',
      label: 'REPORT NAME',
      options: {
        customBodyRender: customReportNameRender,
        sort: false
      }
    },
    {
      name: 'firstAirDate',
      label: 'FIRST AIR DATE',
      options: {
        customBodyRender: customDateRender,
        sort: false
      }
    },
    {
      name: 'length',
      label: 'LENGTH',
      options: {
        customBodyRender: customLengthRender,
        sort: false
      }
    },
    {
      name: 'narrative',
      label: 'NARRATIVE',
      options: {
        customHeadLabelRender: customHeadRender,
        customBodyRender: customNarrativeRender,
        sort: false
      }
    },
    {
      name: 'summary',
      options: { display: false }
    },
    {
      name: 'mentionsCount',
      label: 'MENTIONS',
      options: { customBodyRender: customValueRender }
    },
    {
      name: 'ratingsAndImpactCount',
      label: 'RATINGS / IMPACT',
      options: {
        customHeadLabelRender: customHeadRender,
        customBodyRender: customValueRender
      }
    },
    {
      name: 'promotionType',
      label: 'CAMPAIGN / PARTNER / SYNDICATION',
      options: {
        customBodyRender: customPromotionRender,
        sort: false
      }
    },
    {
      name: 'actions',
      label: isEnabled ? 'EXCLUDE' : 'INCLUDE',
      options: {
        customBodyRender: customActionsRender,
        sort: false
      }
    }
  ];

  const narrativeColumnIndex = tableColumns.map(({ name }) => name).indexOf('narrative');

  const tableThemeOverrides = {
    MUIDataTableHeadRow: {
      root: {
        [`& > :not(:nth-child(${narrativeColumnIndex}))`]: {
          width: 'auto'
        }
      }
    },
    MUIDataTableHeadCell: {
      sortAction: {
        alignItems: 'center',

        '& th': {
          padding: '0px',
          borderBottom: 'none'
        }
      },
      toolButton: {
        marginLeft: '0px',
        marginRight: '0px'
      }
    }
  };

  const onCellClick = (rowData, { rowIndex, colIndex }) => {
    // prevent firing when clicking in narrative, promotion, actions column
    if (![4, 7, 8].includes(colIndex)) {
      const narrative = dataResponse[queryName].rows[rowIndex];

      if (narrative) {
        const tempDrawerDetails = setDrawerDetailsObject(narrative);

        openDashboardDrawer(tempDrawerDetails, drawerContext);
      }
    }
  };

  const tableOptions = {
    selectableRows: 'none',
    filter: false,
    search: false,
    print: false,
    download: false,
    viewColumns: false,
    serverSide: true,
    responsive: 'standard',
    onChangeRowsPerPage: setRowsPerPage,
    onChangePage: setAfter,
    draggableColumns: { enabled: true, transitionTime: 300 },
    page: after,
    rowsPerPage,
    count,
    customFooter,
    sortOrder: { name: sortOrder[0], direction: sortOrder[1] },
    onColumnSortChange: (changedColumn, direction) => setSortOrder([changedColumn, direction]),
    onCellClick
  };

  return (
    <AlbTable
      tableData={tableData}
      tableColumns={tableColumns}
      tableOptions={tableOptions}
      tableThemeOverrides={tableThemeOverrides}
    />
  );
};

NarrativeTable.propTypes = {
  dataResponse: PropTypes.shape(),
  dataRefetch: PropTypes.func.isRequired,
  after: PropTypes.number.isRequired,
  setAfter: PropTypes.func.isRequired,
  setSortOrder: PropTypes.func.isRequired,
  sortOrder: PropTypes.arrayOf(PropTypes.string).isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  isEnabled: PropTypes.bool
};

NarrativeTable.defaultProps = {
  dataResponse: null,
  isEnabled: false
};

export default NarrativeTable;
