/* eslint-disable react/no-children-prop */
import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, Grid, Button, Paper, IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { makeStyles, createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import EventLink from 'views/DashboardInsights/EventLink';
import MDXContent from 'components/MDXContent';
import { ObserveEventsPathV4 } from 'util/paths';
import { goToRoute } from 'util/routesHelpers';

import theme from 'theme';

const components = { EventLink };

const useStyles = makeStyles({
  box: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    borderRadius: '10px',
    padding: '48px',
    fontFamily: 'Poppins'
  },
  title: {
    position: 'relative',
    width: '100%',
    textAlign: 'center',
    fontSize: '20px',
    fontWeight: 500,
    color: '#0A1734',
    marginBottom: '28px'
  },
  summaryTitle: {
    position: 'relative',
    width: '100%',
    textAlign: 'left',
    fontSize: '20px',
    fontWeight: 500,
    color: '#0A1734',
    direction: 'ltr'
  },
  actions: {
    position: 'absolute',
    top: '-9px',
    right: '0px'
  },
  body: {
    fontSize: '20px',
    overflow: 'hidden'
  },
  actionButton: {
    color: '#000000'
  },
  footerButton: {
    color: '#FFFFFF',
    background: '#0A1934',
    padding: '8px 22px',
    '& > span': {
      fontSize: '14px'
    },
    '&:hover': {
      backgroundColor: '#3c5a99'
    }
  },
  markdown: {
    fontFamily: "'Poppins', sans-serif",
    color: 'rgb(13, 26, 58)',

    '& h2': {
      fontSize: '24px',
      fontWeight: 500
    },
    '& h3': {
      fontSize: '18px',
      fontWeight: 500
    },
    '& ol, ul, p, li': {
      fontSize: '16px',
      fontWeight: 400
    }
  }
});

const modalTheme = createTheme({
  ...theme,
  overrides: {
    ...theme.overrides,
    MuiDialog: {
      ...theme.overrides.MuiDialog
    }
  }
});

const CausalChainJustificationModal = props => {
  const {
    title,
    justification,
    endDate,
    isModalOpen,
    onChange,
    feedbackComponent,
    history
  } = props;

  const classes = useStyles();

  const onClose = e => {
    onChange({ isModalOpen: false });
    e.stopPropagation();
  };

  const showMeTheData = e => {
    const dateQueryString =
      endDate != null ? `?date=${moment.utc(endDate).format('YYYY-MM-DD')}` : '';

    goToRoute(`${ObserveEventsPathV4}${dateQueryString}`, history);
    e.stopPropagation();
  };

  return (
    <MuiThemeProvider theme={modalTheme}>
      <Dialog maxWidth="lg" open={isModalOpen} onClose={onClose}>
        <Paper className={classes.box}>
          <div className={classes.title}>
            Justification
            <div className={classes.actions}>
              {feedbackComponent}
              <IconButton className={classes.actionButton} onClick={onClose}>
                <Close />
              </IconButton>
            </div>
          </div>

          <div className={classes.summaryTitle}>{title}</div>

          <Grid className={`${classes.body} ${classes.markdown}`}>
            <MDXContent components={components} content={justification} />
          </Grid>
          <Button className={classes.footerButton} variant="contained" onClick={showMeTheData}>
            SHOW ME THE DATA
          </Button>
        </Paper>
      </Dialog>
    </MuiThemeProvider>
  );
};

CausalChainJustificationModal.propTypes = {
  title: PropTypes.string,
  justification: PropTypes.string,
  // update if needed
  endDate: PropTypes.instanceOf(moment),
  isModalOpen: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  feedbackComponent: PropTypes.node.isRequired,
  history: PropTypes.shape().isRequired
};

CausalChainJustificationModal.defaultProps = {
  title: null,
  justification: null,
  endDate: null
};

export default withRouter(CausalChainJustificationModal);
